<template>
	<div class="opacity-page">
		<div class="header-filter" v-if="loaded">
			<div class="row-space-tbf search-row">
				<div class="space-left"><icon-search v-if="$resize && $mq.above(781)" /></div>
				<div class="content">
					<div class="search-input">
						<input type="text" :placeholder="$t('index-users.search-by')" v-model="search_filter" v-debounce:500="searchFilterFunct" />
					</div>
					<div class="actions-page mobile">
						<button
							class="btn-tbf blue"
							v-if="$auth.user().rights.admins_crud"
							@click="
								$auth.user().rights.can_create_user_limit ? $router.push({ name: 'user-create' }) : showModal('warning_limit_package')
							"
						>
							<div class="icon">
								<icon-plus />
							</div>
							<span v-if="$resize && $mq.above(600)" class="text">{{ $t("index-users.new-user") }}</span>
						</button>

						<button
							v-if="$resize && $mq.above(600)"
							class="btn-tbf grey filter-btn"
							v-bind:class="{ active: extendFilters }"
							@click="extendFilters = !extendFilters"
						>
							<div class="counter" v-bind:class="{ active: queryFiltersCount > 0 }">{{ queryFiltersCount }}</div>
							<span class="text">{{ $t("general.filters") }}</span>
							<div class="icon-filter"><icon-arrow /></div>
						</button>
						<button
							v-else-if="$resize && $mq.below(599)"
							class="btn-tbf grey filter-btn-mobile"
							v-bind:class="{ active: extendFilters }"
							@click="extendFilters = !extendFilters"
						>
							<div class="icon settings"><icon-settings /></div>
						</button>

						<div class="filter-dropdown-header dropdown">
							<button
								class="btn-tbf blue center only-icon"
								id="exportButton"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<div class="loader-spin icon" v-if="loadingExport"></div>
								<icon-download class="icon" v-else />
							</button>
							<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownFilter">
								<div class="dropdown-item" @click="exportUsers('pdf')" key="download-pdf">
									<div class="checkbox">
										<span class="text">{{ $t("index-users.download_pdf") }}</span>
									</div>
								</div>
								<div class="dropdown-item" @click="exportUsers('xls')" key="download-xls">
									<div class="checkbox">
										<span class="text">{{ $t("index-users.download_xls") }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
			<div class="row-space-tbf filters-extend" :class="[{ mobile: $resize && $mq.below(1120) }]" v-if="extendFilters">
				<div class="space-left"></div>
				<div class="content users">
					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedRoles.length">{{ $t("filters.by_roles") }}</span>
							<span class="text" v-else-if="selectedRoles.length == 1">{{
								filterRoles.find((el) => el.id == selectedRoles[0]).name
							}}</span>
							<span class="text" v-else>{{ selectedRoles.length }} {{ $t("filters.roles_selected") }}</span>

							<div class="icon-filter" v-if="!selectedRoles.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedRoles')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchRoles" :placeholder="$t('filters.search_roles')" />
							</div>

							<template v-for="role in filteredRoles">
								<div
									class="dropdown-item"
									@click.stop="selectOptionFilter('selectedRoles', role.id)"
									v-bind:class="{ active: selectedRoles.includes(role.id) }"
									:key="'filter-role-' + role.id"
								>
									<div class="checkbox">
										<div class="checkmark"></div>
										<span class="text">{{ role.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedStatus">{{ $t("filters.by_status") }}</span>
							<span class="text" v-else>{{ $t(`user_archivate.status.${selectedStatus}`) }}</span>

							<div class="icon-filter" v-if="!selectedStatus"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedStatus')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<template v-for="status in usersStatusList">
								<div
									class="dropdown-item"
									@click.stop="selectSingleOptionFilter('selectedStatus', status)"
									v-bind:class="{ active: selectedStatus.includes(status) }"
									:key="'filter-status-' + status"
								>
									<div class="checkbox">
										<div class="checkmark"></div>
										<span class="text">{{ $t(`user_archivate.status.${status}`) }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					<div class="filter-dropdown dropdown" v-if="soft_organigram.is_active">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedResponsibilities.length">{{ $t("filters.by_responsibilities") }}</span>
							<span class="text" v-else-if="selectedResponsibilities.length == 1">{{ getNameResponsibility() }}</span>
							<span class="text" v-else>{{ selectedResponsibilities.length }} {{ $t("filters.responsibilities_selected") }}</span>

							<div class="icon-filter" v-if="!selectedResponsibilities.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedResponsibilities')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchResponsibilities" :placeholder="$t('filters.search_responsibilities')" />
							</div>

							<template v-for="role in filteredResponsibilities" v-if="role.responsibilities.length">
								<div class="dropdown-item disabled" @click.stop="">
									<div class="checkbox">
										<span class="text">{{ role.name }}</span>
									</div>
								</div>
								<div
									class="dropdown-item"
									v-for="responsibility in role.responsibilities"
									@click.stop="selectOptionFilter('selectedResponsibilities', responsibility.id)"
									v-bind:class="{ active: selectedResponsibilities.includes(responsibility.id) }"
									:key="'filter-responsibility-' + responsibility.id"
								>
									<div class="checkbox">
										<div class="checkmark"></div>
										<span class="text">{{ responsibility.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedDepartments.length">{{ $t("filters.by_departments") }}</span>
							<span class="text" v-else-if="selectedDepartments.length == 1">{{
								filterDepartments.find((el) => el.id == selectedDepartments[0]).name
							}}</span>
							<span class="text" v-else>{{ selectedDepartments.length }} {{ $t("filters.departments_selected") }}</span>

							<div class="icon-filter" v-if="!selectedDepartments.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedDepartments')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchDepartments" :placeholder="$t('filters.search_department')" />
							</div>

							<template v-for="department in filteredDepartments">
								<div
									class="dropdown-item"
									@click.stop="selectOptionFilter('selectedDepartments', department.id, department)"
									v-bind:class="{ active: selectedDepartments.includes(department.id) }"
									:key="'filter-department-' + department.id"
								>
									<div
										class="checkbox"
										:class="`level-${department.level_depth}`"
										:style="`padding-left: calc(10px * ${department.level_depth});`"
									>
										<div class="checkmark"></div>
										<span class="text">{{ department.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					<div class="filter-dropdown dropdown" v-if="soft_organigram.is_active">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedWorkstations.length">{{ $t("filters.by_workstations") }}</span>
							<span class="text" v-else-if="selectedWorkstations.length == 1">{{
								filterWorkstations.find((el) => el.id == selectedWorkstations[0]).name
							}}</span>
							<span class="text" v-else>{{ selectedWorkstations.length }} {{ $t("filters.workstations_selected") }}</span>

							<div class="icon-filter" v-if="!selectedWorkstations.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedWorkstations')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchWorkstations" :placeholder="$t('filters.search_workstations')" />
							</div>

							<template v-for="workstation in filteredWorkstations">
								<div
									class="dropdown-item"
									@click.stop="selectOptionFilter('selectedWorkstations', workstation.id)"
									v-bind:class="{ active: selectedWorkstations.includes(workstation.id) }"
									:key="'filter-workstation-' + workstation.id"
								>
									<div class="checkbox">
										<div class="checkmark"></div>
										<span class="text">{{ workstation.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown" v-if="soft_organigram.is_active">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedSkills.length">{{ $t("filters.by_skills") }}</span>
							<span class="text" v-else-if="selectedSkills.length == 1">{{
								filterSkills.find((el) => el.id == selectedSkills[0]).name
							}}</span>
							<span class="text" v-else>{{ selectedSkills.length }} {{ $t("filters.skills_selected") }}</span>

							<div class="icon-filter" v-if="!selectedSkills.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedSkills')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchSkills" :placeholder="$t('filters.search_skills')" />
							</div>

							<template v-for="skill in filteredSkills">
								<div
									class="dropdown-item"
									@click.stop="selectOptionFilter('selectedSkills', skill.id)"
									v-bind:class="{ active: selectedSkills.includes(skill.id) }"
									:key="'filter-skill-' + skill.id"
								>
									<div class="checkbox">
										<div class="checkmark"></div>
										<span class="text">{{ skill.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					<button class="btn-tbf white clear-filters" @click.stop="clearFilter('all')">
						<icon-trash v-if="$resize && $mq.above(1121)" />
						<div v-else-if="$resize && $mq.below(1120)">{{ $t("filters.clear_all") }}</div>
					</button>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
		<loader-header-list v-else />

		<template v-if="loadedList">
			<div class="data-list list-goals" v-if="users.length">
				<div class="row-space-tbf header-count-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="text-list-length">
							{{ $t("general.list_length") }} <span class="number">{{ totalResults }}</span>
						</div>
					</div>
					<div class="space-right"></div>
				</div>

				<div class="row-space-tbf header-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter column-user" v-if="$resize && $mq.above(501)"></div>
						<div
							class="column-filter sortable column-name"
							@click="sortList('first_name')"
							v-bind:class="[sortBy == 'first_name' ? 'sort ' + sortDirection : '']"
						>
							<div class="text">{{ $t("general.name") }}</div>
							<div class="icon-filter"><icon-arrow /></div>
						</div>
						<div v-if="$resize && $mq.above(1350) && selectedStatus == 'archived'" class="column-filter column-reason">
							<div class="text">{{ $t("index-users.reason") }}</div>
						</div>
						<div v-if="$resize && $mq.above(690)" class="column-filter column-roles">
							<div class="text">{{ $t("index-users.roles") }}</div>
						</div>
						<div v-if="$resize && $mq.above(870)" class="column-filter column-departments">
							<div class="text">{{ $t("index-users.departments") }}</div>
						</div>
						<div v-if="$resize && $mq.above(1020)" class="column-filter column-phone">
							<div class="text">{{ $t("index-users.phone") }}</div>
						</div>
						<div class="column-filter column-edit-mobile" v-if="$resize && $mq.below(500)"></div>
					</div>
					<div class="space-right"></div>
				</div>

				<template v-for="user in users">
					<div class="row-space-tbf row-list-item" :key="user.id">
						<div class="space-left"><icon-people v-if="$resize && $mq.above(781)" /></div>
						<div class="content">
							<div class="border-hover" v-if="$resize && $mq.above(781)"></div>
							<div class="column-avatar column-user" :class="[{ hide: $resize && !$mq.above(501) }]">
								<img :src="user.avatar" v-if="user.avatar" />
								<div class="user-circle" v-else>
									<icon-user />
								</div>
							</div>
							<router-link :to="{ name: 'user-show', params: { slug: user.slug } }" class="column-name-desc column-name link">
								<div class="name">{{ user.first_name + " " + user.last_name }}</div>
								<div class="description" v-if="!user.dummy_account">{{ user.email }}</div>
							</router-link>
							<div v-if="$resize && $mq.above(1350) && selectedStatus == 'archived'" class="column-simple-text column-reason">
								<v-popover
									offset="5"
									trigger="hover"
									placement="bottom"
									popoverBaseClass="popover-tbf"
									:delay="{ show: 200, hide: 0 }"
								>
									<div class="text">{{ user.archived_reason }}</div>
									<template slot="popover">
										<div class="simple-text">{{ user.archived_reason }}</div>
									</template>
								</v-popover>
							</div>
							<div v-if="$resize && $mq.above(690)" class="column-simple-text column-roles">
								<v-popover
									offset="5"
									trigger="hover"
									placement="bottom"
									popoverBaseClass="popover-tbf"
									:delay="{ show: 200, hide: 0 }"
								>
									<div class="text roles">
										<span class="first" v-if="user.roles.length == 1">{{ user.roles[0] }}</span>
										<span v-else-if="user.roles.length > 1">{{ `${user.roles[0]} (+${user.roles.length - 1})` }}</span>
									</div>
									<template slot="popover">
										<div class="simple-text">{{ user.roles.join(", ") }}</div>
									</template>
								</v-popover>
							</div>
							<div v-if="$resize && $mq.above(870)" class="column-simple-text column-departments">
								<v-popover
									offset="5"
									trigger="hover"
									placement="bottom"
									popoverBaseClass="popover-tbf"
									:delay="{ show: 200, hide: 0 }"
								>
									<div class="text departments">
										<span class="first" v-if="user.departments.length == 1">{{ user.departments[0] }}</span>
										<span v-else-if="user.departments.length > 1">{{
											`${user.departments[0]} (+${user.departments.length - 1})`
										}}</span>
									</div>
									<template slot="popover">
										<div class="simple-text">{{ user.departments.join(", ") }}</div>
									</template>
								</v-popover>
							</div>
							<div v-if="$resize && $mq.above(1020)" class="column-simple-text column-phone">
								<div class="text">{{ user.phone }}</div>
							</div>
							<div class="column-edit-mobile" v-if="$resize && $mq.below(500) && (user.rights.edit || user.rights.delete)">
								<div class="dropdown dropdown-edit">
									<div class="button-edit" :ref="'dropdownEditRef' + user.id" :id="'dropdownEdit' + user.id" data-toggle="dropdown">
										<icon-edit-mobile />
									</div>
									<div
										class="dropdown-menu dropdown-menu-right"
										:id="'dropdownMenu' + user.id"
										:aria-labelledby="'dropdownEdit' + user.id"
									>
										<div class="dropdown-item">
											<div class="simple-text">{{ $t("general.view") }}</div>
										</div>
										<div class="dropdown-item" @click="editUser(user.slug)" v-if="user.rights.edit">
											<div class="simple-text">{{ $t("general.edit") }}</div>
										</div>
										<div
											class="dropdown-item"
											@click="showModal('reset_password', { slug: user.slug, email: user.email })"
											v-if="user.rights.edit"
										>
											<div class="simple-text">{{ $t("general.reset_password_link") }}</div>
										</div>
										<div
											class="dropdown-item"
											@click="
												showModal('vacant_job_from_user', {
													slug: user.slug,
													userName: `${user.last_name ? user.last_name : ''}`,
												})
											"
											v-if="$auth.user().rights.admins_crud"
										>
											<div class="simple-text">{{ $t("index-users.generate_post_vacant") }}</div>
										</div>
										<div
											class="dropdown-item"
											@click="showModal('confirm_archiving', { userSlug: user.slug, status: user.status })"
											v-if="user.rights.delete"
										>
											<div class="simple-text">
												{{ user.status == "active" ? $t("general.archived") : $t("general.activate") }}
											</div>
										</div>
										<div
											class="dropdown-item"
											@click="showModal('delete', { type: 'user', from: 'index_users', model: user })"
											v-if="user.rights.delete"
										>
											<div class="simple-text">{{ $t("general.delete") }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="space-right">
							<div v-if="$resize && $mq.above(501)" class="dropdown dropdown-edit">
								<div class="button-edit" :ref="'dropdownEditRef' + user.id" :id="'dropdownEdit' + user.id" data-toggle="dropdown">
									<icon-edit />
								</div>
								<div
									class="dropdown-menu dropdown-menu-right"
									:id="'dropdownMenu' + user.id"
									:aria-labelledby="'dropdownEdit' + user.id"
								>
									<div class="dropdown-item" @click="showUser(user.slug)">
										<div class="simple-text">{{ $t("general.view") }}</div>
									</div>
									<template v-if="user.rights.edit || user.rights.delete">
										<div class="dropdown-item" @click="editUser(user.slug)" v-if="user.rights.edit">
											<div class="simple-text">{{ $t("general.edit") }}</div>
										</div>
										<div
											class="dropdown-item"
											@click="showModal('reset_password', { slug: user.slug, email: user.email })"
											v-if="user.rights.edit"
										>
											<div class="simple-text">{{ $t("general.reset_password_link") }}</div>
										</div>
										<div
											class="dropdown-item"
											@click="
												showModal('vacant_job_from_user', {
													slug: user.slug,
													userName: `${user.last_name ? user.last_name : ''}`,
												})
											"
											v-if="$auth.user().rights.admins_crud"
										>
											<div class="simple-text">{{ $t("index-users.generate_post_vacant") }}</div>
										</div>
										<div
											class="dropdown-item"
											@click="showModal('confirm_archiving', { userSlug: user.slug, status: user.status })"
											v-if="user.rights.delete"
										>
											<div class="simple-text">
												{{ user.status == "active" ? $t("general.archived") : $t("general.activate") }}
											</div>
										</div>
										<div
											class="dropdown-item"
											@click="showModal('delete', { type: 'user', from: 'index_users', model: user })"
											v-if="user.rights.delete"
										>
											<div class="simple-text">{{ $t("general.delete") }}</div>
										</div>
									</template>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
			<div class="row-space-tbf list-empty" v-else>
				<div class="space-left"></div>
				<div class="content full">
					<!-- <div class="icon-empty">
						<img src="/build/images/no-results-found.svg">
					</div> -->
					<div class="title">{{ $t("empty.title") }}</div>
					<div class="description" v-html="$t('empty.users_text')"></div>

					<div class="add-button" v-if="$auth.user().rights.admins_crud">
						<button class="btn-tbf blue center" @click="$router.push({ name: 'user-create' })">
							<div class="icon"><icon-plus class="white" /></div>
							<div class="text">{{ $t("index-users.new-user") }}</div>
						</button>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</template>
		<loader-items-list class="position-absolut-list-loader" v-else />

		<infinite-loading :identifier="infiniteId" @infinite="infiniteUsersHandler" ref="infiniteLoading"
			><div slot="spinner"></div>
			<div slot="no-more"></div>
			<div slot="no-results"></div
		></infinite-loading>
	</div>
</template>

<script>
import IconSearch from "../../Icons/Search";
import IconPlus from "../../Icons/Plus";
import IconArrow from "../../Icons/Arrow";
import IconUser from "../../Icons/User";
import IconPeople from "../../Icons/User";
import IconEdit from "../../Icons/EditDots";
import IconEditMobile from "../../Icons/Edit";
import IconTrash from "../../Icons/Trash";
import IconSettings from "../../Icons/Settings";
import IconClose from "../../Icons/Close";
import LoaderItemsList from "../../PagesLoaders/ItemsList";
import LoaderHeaderList from "../../PagesLoaders/HeaderList";
import InfiniteLoading from "vue-infinite-loading";
import IconDownload from "../../Icons/Download";
import IconEye from "../../Icons/ShowEye";

export default {
	components: {
		IconSearch,
		IconPlus,
		IconArrow,
		IconUser,
		IconPeople,
		IconEdit,
		IconEditMobile,
		IconTrash,
		IconSettings,
		IconClose,
		LoaderItemsList,
		LoaderHeaderList,
		InfiniteLoading,
		IconDownload,
		IconEye,
	},
	data() {
		return {
			loaded: false,
			loadedList: false,
			search_filter: "",
			users: [],
			sortBy: "first_name",
			sortDirection: "asc",
			page: 0,
			infiniteId: 1,
			queryFiltersCount: 0,
			extendFilters: true,
			filterSkills: [],
			selectedSkills: [],
			searchSkills: "",
			filterRoles: [],
			selectedRoles: [],
			searchRoles: "",
			filterResponsibilities: [],
			selectedResponsibilities: [],
			searchResponsibilities: "",
			filterDepartments: [],
			selectedDepartments: [],
			searchDepartments: "",
			filterWorkstations: [],
			selectedWorkstations: [],
			searchWorkstations: "",
			usersStatusList: ["active", "archived"],
			selectedStatus: "",
			totalResults: 0,
			loadingExport: false,
		};
	},
	watch: {
		search_filter: function(val) {
			var data = Object.assign({}, this.$route.query);
			data["search"] = this.search_filter;

			if (this.search_filter == "") {
				delete data["search"];
			}

			this.$router.push({ query: data });
		},
	},
	computed: {
		filteredRoles() {
			return getByKeywordFilter(this.filterRoles, this.searchRoles);
		},
		filteredResponsibilities() {
			return getByKeywordFilterRes(this.filterResponsibilities, this.searchResponsibilities);
		},
		filteredDepartments() {
			return getByKeywordFilter(this.filterDepartments, this.searchDepartments);
		},
		filteredWorkstations() {
			return getByKeywordFilter(this.filterWorkstations, this.searchWorkstations);
		},
		filteredSkills() {
			return getByKeywordFilter(this.filterSkills, this.searchSkills);
		},
		soft_organigram() {
			return this.$store.getters["applications/getApplication"]("Organigrama");
		},
	},
	async mounted() {
		this.$refs.infiniteLoading.status = 1;
		this.$refs.infiniteLoading.$emit("infinite", this.$refs.infiniteLoading.stateChanger);

		await this.getFiltersOrg();
		await this.checkQueryFilters();

		setTimeout(() => {
			var title = this.$t("navbar.users");
			this.$root.$emit("navbar_title", title);
		}, 0);

		this.$root.$on("refreshUsersIndex", () => {
			this.getFiltersOrg();
			this.checkQueryFilters();
			this.searchFilterFunct();
		});
	},
	methods: {
		async getFiltersOrg() {
			await axios
				.get(`instances/${this.$auth.user().instance.id}/filter`, {
					params: {
						roles: true,
						responsibilities: true,
						departments_level: true,
						workstations: true,
						skills: true,
					},
				})
				.then(({ data }) => {
					this.filterRoles = data.data.roles;
					this.filterResponsibilities = data.data.roles;
					this.filterDepartments = data.data.departments_level;
					this.filterWorkstations = data.data.workstations;
					this.filterSkills = data.data.skills;
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					this.loaded = true;
				});
		},
		infiniteUsersHandler($state) {
			var paramsCall = { view_more: this.page * 20 };
			this.queryObject(paramsCall);

			if (this.sortBy) {
				paramsCall.sort_by = this.sortBy;
				paramsCall.sort_direction = this.sortDirection;
			}

			axios
				.get(`/${this.$auth.user().instance.id}/users`, { params: paramsCall })
				.then(({ data }) => {
					this.totalResults = data.total_results;
					if (data.data.length) {
						this.page++;
						this.users.push(...data.data);
						setTimeout(() => {
							$state.loaded();
						}, 200);
						if (data.data.length < 20) {
							$state.complete();
						}
					} else {
						$state.complete();
					}
					this.loadedList = true;
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true;
						setTimeout(() => {
							$(".opacity-page").addClass("show");
						}, 0);
					}, 0);
				});
		},
		queryObject(paramsCall) {
			var dataQuery = Object.assign({}, this.$route.query);

			Object.keys(dataQuery).forEach((key) => {
				if (key == "search") {
					paramsCall.search = dataQuery[key];
				}
				if (key == "roles") {
					paramsCall.role_ids = dataQuery[key].split(",").map(Number);
				}
				// if(key == 'responsibilities'){ paramsCall.responsibility_ids = dataQuery[key].split(',').map(Number) }
				if (key == "role_responsibilities") {
					paramsCall.role_responsibility_ids = dataQuery[key].split(",").map(Number);
				}
				if (key == "departments") {
					paramsCall.department_ids = dataQuery[key].split(",").map(Number);
				}
				if (key == "workstations") {
					paramsCall.workstation_ids = dataQuery[key].split(",").map(Number);
				}
				if (key == "status") {
					paramsCall.status = dataQuery[key].split(",");
				}
				if (key == "skills") {
					paramsCall.skill_ids = dataQuery[key].split(",");
				}
			});
		},
		selectOptionFilter(filter, value, customValue = false) {
			// Custom functionality, check all childrens departments
			if (customValue) {
				var arrayTreeDepartments = [];
				arrayTreeDepartments.push(value);
				arrayTreeDepartments = this.getAllChildrensDepartments(customValue, arrayTreeDepartments);

				if (this[filter].includes(parseInt(value))) {
					arrayTreeDepartments.map((item) => {
						if (this[filter].includes(parseInt(item))) {
							this[filter].splice(this[filter].indexOf(parseInt(item)), 1);
						}
					});
				} else {
					arrayTreeDepartments.map((item) => {
						if (!this[filter].includes(parseInt(item))) {
							this[filter].push(parseInt(item));
						}
					});
				}
			} else {
				if (typeof value == "string") {
					if (this[filter].includes(value)) {
						this[filter].splice(this[filter].indexOf(value), 1);
					} else {
						this[filter].push(value);
					}
				} else {
					if (this[filter].includes(parseInt(value))) {
						this[filter].splice(this[filter].indexOf(parseInt(value)), 1);
					} else {
						this[filter].push(parseInt(value));
					}
				}
			}

			this.addFilterToRouteQuery(filter);
		},
		selectSingleOptionFilter(filter, value) {
			if (this[filter] != value) {
				this[filter] = value;
			} else {
				this[filter] = "";
			}

			this.addFilterToRouteQuery(filter);
		},
		addFilterToRouteQuery(filterName) {
			var dataQuery = Object.assign({}, this.$route.query);

			if (filterName == "selectedRoles" || filterName == "all") {
				dataQuery["roles"] = this.selectedRoles.toString();
			}
			if (filterName == "selectedResponsibilities" || filterName == "all") {
				dataQuery["role_responsibilities"] = this.selectedResponsibilities.toString();
			}
			if (filterName == "selectedWorkstations" || filterName == "all") {
				dataQuery["workstations"] = this.selectedWorkstations.toString();
			}
			if (filterName == "selectedDepartments" || filterName == "all") {
				dataQuery["departments"] = this.selectedDepartments.toString();
			}
			if (filterName == "selectedStatus" || filterName == "all") {
				dataQuery["status"] = this.selectedStatus.toString();
			}
			if (filterName == "selectedSkills" || filterName == "all") {
				dataQuery["skills"] = this.selectedSkills.toString();
			}

			Object.keys(dataQuery).map(function(key, value) {
				if (dataQuery[key] == "") {
					delete dataQuery[key];
				}
			});

			this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length;

			this.$router.push({ query: dataQuery });
			this.searchFilterFunct();
		},
		clearFilter(filter) {
			if (filter == "all") {
				this.selectedRoles = [];
				this.selectedResponsibilities = [];
				this.selectedWorkstations = [];
				this.selectedDepartments = [];
				this.selectedSkills = [];
			} else {
				if (filter == "selectedStatus") {
					this[filter] = "";
				} else {
					this[filter] = [];
				}
			}

			this.addFilterToRouteQuery(filter);
		},
		checkQueryFilters() {
			var dataQuery = Object.assign({}, this.$route.query);

			Object.keys(dataQuery).forEach((key) => {
				if (key == "search") {
					this.search_filter = dataQuery[key];
				}

				if (key == "roles") {
					this.selectedRoles = dataQuery[key].split(",").map(Number);
				}
				if (key == "role_responsibilities") {
					this.selectedResponsibilities = dataQuery[key].split(",").map(Number);
				}
				if (key == "workstations") {
					this.selectedWorkstations = dataQuery[key].split(",").map(Number);
				}
				if (key == "departments") {
					this.selectedDepartments = dataQuery[key].split(",").map(Number);
				}
				if (key == "status") {
					this.selectedStatus = dataQuery[key].split(",");
				}
				if (key == "skills") {
					this.selectedSkills = dataQuery[key].split(",");
				}
			});

			this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length;
		},
		sortList(column) {
			if (column === this.sortBy) {
				this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
			}
			this.sortBy = column;

			this.searchFilterFunct();
		},
		showUser(userSlug) {
			this.$router.push({ name: "user-show", params: { slug: userSlug } });
		},
		editUser(userSlug) {
			this.$router.push({ name: "user-edit", params: { slug: userSlug } });
		},
		showModal(type, data = false, itemId = false) {
			this.$root.$emit("open_modal", type, data, itemId);
		},
		searchFilterFunct() {
			this.loadedList = false;
			this.page = 0;
			this.users = [];
			this.infiniteId += 1;
		},
		getNameResponsibility() {
			var name = "";
			this.filterResponsibilities.forEach((el) => {
				var checkItem = el.responsibilities.find((elem) => elem.id == this.selectedResponsibilities[0]);
				if (checkItem) {
					name = checkItem.name;
				}
			});
			return name;
		},
		exportUsers(type) {
			this.loadingExport = true;
			var btnSubmit = document.getElementById("exportButton");
			btnSubmit.disabled = true;

			var showColumnsObj = ["first_name", "last_name", "email", "status", "right", "roles", "departments", "phone"];

			if (this.selectedStatus == "archived") {
				showColumnsObj.splice(4, 0, "archived_reason");
			}

			var paramsCall = {};
			this.queryObject(paramsCall);
			paramsCall.type = type;
			paramsCall.show_columns = showColumnsObj;

			axios
				.get(`/reports/${this.$auth.user().instance.id}/users`, {
					params: paramsCall,
					responseType: "blob",
				})
				.then((response) => {
					let blob = new Blob([response.data], { type: "application/octet-stream" });
					let fileURL = window.URL.createObjectURL(blob);

					let fileName = this.$auth.user().instance.company_name + " - " + this.$t("general.users") + (type == "xls" ? ".xlsx" : ".pdf");

					let fileLink = document.createElement("a");
					fileLink.href = fileURL;
					fileLink.setAttribute("download", fileName);
					document.body.appendChild(fileLink);

					fileLink.click();
					setTimeout(() => {
						this.loadingExport = false;
						btnSubmit.disabled = false;
					}, 500);
				});
		},
		getAllChildrensDepartments(department, arrayIds) {
			if (this.filterDepartments.filter((el) => el.related_to == department.id).length) {
				this.filterDepartments
					.filter((el) => el.related_to == department.id)
					.map((dep) => {
						arrayIds.push(dep.id);
						arrayIds = this.getAllChildrensDepartments(dep, arrayIds);
					});
			}

			return arrayIds;
		},
	},
};
function getByKeywordFilter(list, keyword) {
	const search = keyword.trim();

	if (!search.length) return list;
	return list.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
}
function getByKeywordFilterRes(list, keyword) {
	const search = keyword.trim();

	if (!search.length) return list;

	return list.reduce(function(filtered, option) {
		if (option.responsibilities.filter((elem) => elem.name.toLowerCase().indexOf(search.toLowerCase()) > -1).length) {
			var someNewValue = {
				...option,
				responsibilities: option.responsibilities.filter((elem) => elem.name.toLowerCase().indexOf(search.toLowerCase()) > -1),
			};
			filtered.push(someNewValue);
		}
		return filtered;
	}, []);
}
</script>

<style lang="scss">
.list-goals {
	.row-list-item {
		&:hover {
			.content {
				.column-name {
					.name {
						font-weight: 700;
					}
				}
			}
		}
	}
	.column-roles {
		flex: 0 0 180px;
		.roles {
			display: flex;
			padding-right: 15px;
			.word {
				padding-right: 10px;
			}
			.number {
				padding-right: 10px;
				text-align: center;
				min-width: 57px;
				line-height: 16px;
				padding-bottom: 3px;
				margin: auto;
			}
		}
	}
	.column-departments {
		flex: 0 0 180px;
		.departments {
			display: flex;
			padding-right: 15px;
			.word {
				padding-right: 10px;
			}
			.number {
				padding-right: 10px;
				text-align: center;
				min-width: 57px;
				line-height: 16px;
				padding-bottom: 3px;
				margin: auto;
			}
		}
	}
	.column-reason {
		flex: 1 0 150px;
		overflow: hidden;
		max-width: 150px;
		.trigger {
			overflow: hidden;
			.text {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}
	}
	.column-name {
		flex: 1 0 auto;
		&.link {
			cursor: pointer;
			outline: none;
		}
	}
	.column-rights {
		flex: 0 0 150px;
	}
	.column-phone {
		flex: 0 0 100px;
	}
	.column-user {
		flex: 0 0 60px;
		display: flex;
		justify-content: flex-start;
	}
}
.dropdown-item {
	&.disabled {
		color: #afb1b8 !important;
	}
}
.filter-btn-mobile {
	.icon {
		svg {
			fill: black;
		}
	}
}
</style>
